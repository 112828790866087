/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/contractManagement/sign',
    name: 'contractSign',
    meta: {
      requireAuth: false,
      group: '合同管理',
      title: '合同签署'
    },
    component: () => import('@/views/contractManagement/sign/list')
  },
  {
    path: '/contractManagement/sign/offlineDetail',
    name: 'signOfflineDetail',
    meta: {
      requireAuth: false,
      group: '合同管理',
      parentName: 'contractSign',
      title: '上传线下合同'
    },
    component: () => import('@/views/contractManagement/sign/offlineDetail')
  },
  {
    path: '/contractManagement/sign/detail',
    name: 'signDetail',
    meta: {
      requireAuth: false,
      group: '合同管理',
      parentName: 'contractSign',
      title: '详情'
    },
    component: () => import('@/views/contractManagement/sign/detail')
  }
]
