import commonAxios from 'axios'
import { Message } from 'element-ui'
import store from '../../store/index'
import cfca from './cfca-prove'
import utils from './utils'
// axios 配置
commonAxios.defaults.timeout = process.env.VUE_APP_REQUEST_TIMEOUT * 1000
commonAxios.defaults.baseURL = process.env.VUE_APP_REQUEST_BASE_URL
commonAxios.defaults.params = {
  token: store.state.token
}

commonAxios.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers.Token = store.state.token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

commonAxios.interceptors.request.use(function (config) {
  if (!config.params) {
    config.params = {}
    // config.params.appKey = process.env.VUE_APP_APP_KEY
  }
  config.params._t = Date.parse(new Date()) / 1000
  return config
}, function (err) {
  return Promise.reject(err)
})

// http response 拦截器
commonAxios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401: // 无访问权限
        if (error.config.customErr && error.config.customErr.code === 401) {
          return Promise.reject(error)
        } else {
          Message.error(error.response.data.message ? error.response.data.message : '系统异常，请稍后重试')
          //window.location.href = '/401'
        }
        break
      case 403: // 未登录
        store.commit('removeEmpInfo')
        // if (window.location.hostname === process.env.VUE_APP_platform) {
        //   window.location.href = process.env.VUE_APP_platform + '/login?redirecturl='
        // } else {
        //   window.location.href = process.env.VUE_APP_client + '/loginPartner?redirecturl='
        // }
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
        break
      case 409: // 操作冲突
        if (error.config.customErr && error.config.customErr.code === 409) {
          return Promise.reject(error)
        } else {
          Message.error(error.response.data.message ? error.response.data.message : '系统异常，请稍后重试')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        break
      default:
        if (error.config.customErr && error.config.customErr.code === 'default') {
        } else if (error.response.headers.code === '60000') {
          if (utils.getExplorer() === 'ie') {
            let path = ''
            if (error.response.data.path) {
              path = error.response.data.path
            } else {
              let str = error.response.config.url
              let index1 = str.indexOf('/')
              let str2 = str.substr(index1+1,str.length)
              let index2 = str2.indexOf('/')
              let str3 = str2.substr(index2,str2.length)
              path = str3
            }
            console.log(path)
            cfca.dataHunter(error.response.headers.employeecode, path)
          } else {
            Message.warning('当前浏览器为：'+ utils.getExplorer() + ',请使用IE浏览器进行数字证书身份认证')
          }
        } else {
          Message.error(error.response.data.message ? error.response.data.message : '系统异常，请稍后重试')
        }
        return Promise.reject(error)
    }
  } else {
    error.response = {}
    if (error.config.customErr && error.config.customErr.code === 'default') {
    } else {
      Message.error('系统异常，请稍后重试')
    }
    return Promise.reject(error.response.data)
  }
})
export default commonAxios
