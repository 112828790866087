const constants = {
  // 表格边框
  border: false,
  // 表格斑马线
  stripe: true,
  // 列对齐
  align: 'left',
  // 列表是否一行展示
  showOverflowTooltip: true,
  // 新增编辑弹窗不关闭
  closeByModelEdit: false,
  // 详情弹窗关闭
  closeByModelDetail: true,
  // 表单默认：
  labelSuffix: '：',
  // 数字输入默认属性
  baseInputNumberProp: {
    controls: false,
    min: 0,
    step: 0.01,
    precision: 2,
    stepStrictly: true
  },
  regExpRules: {
    decimal2: {
      regExp: /^(\d+)(.[0-9]{1,2})?$/,
      message: '最多输入两位小数'
    },
    decimal8: {
      regExp: /^(\d+)(.[0-9]{1,8})?$/,
      message: '最多输入八位小数'
    }
  },
  bankInfoList: [
    { label: '邮储银行', value: '100' },
    { label: '工商银行', value: '102' },
    { label: '农业银行', value: '103' },
    { label: '中国银行', value: '104' },
    { label: '建设银行', value: '105' },
    { label: '国家开发银行', value: '201' },
    { label: '中国农业发展银行', value: '202' },
    { label: '交通银行', value: '301' },
    { label: '中信银行', value: '302' },
    { label: '光大银行', value: '303' },
    { label: '华夏银行', value: '304' },
    { label: '民生银行', value: '305' },
    { label: '广发银行', value: '306' },
    { label: '平安银行', value: '307' },
    { label: '招商银行', value: '308' }
  ],
  // 启用状态
  enableStatus: [
    { label: '已启用', value: 1 },
    { label: '已禁用', value: 0 }
  ],
  coreQuotaStatus: [
    { label: '保存', value: 0, isShow: 0},
    { label: '申请', value: 1, isShow: 1 },
    { label: '通过', value: 2, isShow: 1 },
    { label: '驳回', value: 3, isShow: 1 },
    { label: '关闭', value: 4, isShow: 1 }
  ],
  modelStatus: [
    { label: '已上架', value: 1 },
    { label: '下架中', value: 0 }
  ],
  verifyStatus: [
    { label: '未核销', value: 0 },
    { label: '已核销', value: 1 }
  ],
  productAuditStatus: [
    { label: '待审批', value: 0 },
    { label: '审批通过', value: 1 },
    { label: '审批拒绝', value: 2 },
    { label: '变更待审批', value: 3 },
    { label: '变更审批通过', value: 4 },
    { label: '变更审批拒绝', value: 5 }
  ],
  modelType: [
    { label: '授信', value: 'credit', type: 1 },
    { label: '用信', value: 'use', type: 1 },
    { label: '其他', value: 'other', type: 0 }
  ],
  isOnLine: [
    { label: '是', value: 0 },
    { label: '否', value: 1 }
  ],
  ownFunds: [
    { label: '线上', value: 0 },
    { label: '线下', value: 1 }
  ],
  attribute: [
    { label: '还款方式', value: 'method' },
    { label: '单笔借款金额范围', value: 'amountRange' },
    { label: '借贷期限', value: 'loanCeiling' },
    { label: '免收费期限', value: 'minCycle' },
    { label: '日利率', value: 'dayRate' },
    { label: '罚息', value: 'penaltyInterest' },
    { label: '展期', value: 'extension' },
    { label: '分期', value: 'byStages' },
    { label: '逾期期限', value: 'overdue' },
    { label: '融资比例', value: 'financing' },
    { label: '日服务费率', value: 'serviceCharge' }
  ],
  // 启用状态
  productType: [
    { label: '应收账款融资', value: 1 }
  ],
  repaymentType: [
    { label: '随借随还', value: 'borrowing' },
    { label: '先息后本', value: 'firstRest' },
    { label: '定期还息', value: 'regular' }
  ],
  signingMethodType: [
    { label: '线上', value: 1 },
    { label: '线下', value: 0 }
  ],
  productState: [
    { label: '待生效', value: 'Wait' },
    { label: '已上架', value: 'BeenOn' },
    { label: '已下架', value: 'BeenOff' }
  ],
  rollType: [
    { label: '是', value: 'yes' },
    { label: '否', value: 'no' }
  ],
  assetsContractStatus: [
    { label: '未使用', value: 0 },
    { label: '已使用', value: 1 }
  ],
  authenticRightStatus: [
    { label: '未确认', value: 0 },
    { label: '已确认', value: 1 }
  ],
  companyType: [
    { label: '核心企业', value: 'finance-frame-core', states: 1 },
    { label: '融资企业', value: 'finance-frame-customer', states: 1 },
    { label: '资金方', value: 'finance-frame-capital', states: 1 },
    { label: '运营方', value: 'finance-frame-channel', states: 0 }
  ],
  isNeedRisk: [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ],
  accountState: [
    { label: '待激活', value: '0' },
    { label: '未生效', value: '1' },
    { label: '审核中', value: '2' },
    { label: '正常', value: '3' }
  ],
  whiteBusinessState: [
    { label: '移入待审批', value: 1 },
    { label: '导入待审批', value: 2 },
    { label: '审批通过', value: 3 },
    { label: '移入白名单拒绝', value: 4 },
    { label: '移入黑名单拒绝', value: 5 },
    { label: '导入审批拒绝', value: 6 }
  ],
  relationType: [
    { label: '核心企业端', value: 1 },
    { label: '运营端', value: 2 }
  ],
  applicantType: [
    { label: '法人', value: 1 },
    { label: '股东', value: 2 },
    { label: '非法人', value: 3 }
  ],
  documentType: [
    { label: '居民身份证', value: 1 },
    { label: '护照', value: 2 },
    { label: '港澳居民往来内地通行证', value: 3 },
    { label: '台湾居民往来内地通行证', value: 4 }
  ],
  legalCredentialType: [
    { label: '居民身份证', value: '0'},
    { label: '外国护照', value: '2' },
    { label: '港澳居民往来内地通行证', value: '5' },
    { label: '台湾居民往来内地通行证', value: '6' }
  ],
  creditApplicantType: [
    { label: '法人', value: 'LP' },
    { label: '股东、非法人', value: 'SH' }
  ],
  customerState: [
    { label: '提交', value: 1 },
    { label: '平台审批通过', value: 2 },
    { label: '银行审批通过', value: 3 },
    { label: '平台拒绝', value: 4 },
    { label: '银行拒绝', value: 5 }
  ],
  invoiceType: [
    { label: '专票', value: '01' },
    { label: '普票', value: '04' },
    { label: '电⼦票', value: '10' }
  ],
  invoiceKindType: [
    { label: '历史发票', value: '01' },
    { label: '应收发票', value: '02' }
  ],
  invoiceStatus: [
    { label: '已使用', value: 'Y' },
    { label: '未使用', value: 'N' },
    { label: '使用中', value: 'E' }
  ],
  employeeStatus: [
    { label: '已启用', value: '20' },
    { label: '已禁用', value: '10' }
  ],
  flowStateCode: [
    { label: '申请', value: 'Wait' },
    { label: '补件', value: 'UseSupplement' },
    { label: '审批', value: 'UseAudit' },
    { label: '放款', value: 'UseAmount' },
    { label: '待还款', value: 'Repaid' },
    { label: '完成', value: 'Fail' },
    { label: '关闭', value: 'Completed' }
  ],
  distCompanyNameType: [],
  labelType: [
    { label: '高风险', value: '21' },
    { label: '其他', value: '01' }
  ],
  loanFlowStateCode: [
    { label: '待补件', value: 'supplement' },
    { label: '待确认', value: 'confirm' },
    { label: '合同待上传', value: 'contract' },
    { label: '审核中', value: 'toExamine' },
    { label: '已拒绝', value: 'refuse' },
    { label: '还款中', value: 'repayment' },
    { label: '已结清', value: 'settle' }
  ],
  ifFlag: [
    { label: '是', value: true },
    { label: '否', value: false }
  ],
  warehouseStatus: [
    { label: '未使用', value: 'N' },
    { label: '已使用', value: 'Y' },
    { label: '使用中', value: 'E' }
  ],
  informationType: [
    { label: '注册', value: '70' },
    { label: '授信', value: '50' },
    { label: '贷款', value: '20' }
  ],
  messageStatus: [
    { label: '已读', value: 1 },
    { label: '未读', value: 0 }
  ],
  currentStatus: [
    { label: '未还款', value: 'Wait' },
    { label: '部分还款', value: 'Part' },
    { label: '还款中', value: 'Hand' },
    { label: '还款完成', value: 'Finish' },
    { label: '已逾期', value: 'Expected' }
  ],
  //还款详情中的表单状态
  recordStatus: [
    { label: '待还款', value: 'Wait' },
    { label: '部分还款', value: 'Part' },
    { label: '还款中', value: 'Hand' },
    { label: '财务确认', value: 'Confirm' },
    { label: '业务关联', value: 'Business' },
    { label: '还款完成', value: 'Finish' },
    { label: '财务退回', value: 'Return' }
  ],
  contractApprovalStatus: [
    { label: '待审批', value: 0 },
    { label: '审批通过', value: 1 },
    { label: '审批拒绝', value: 2 },
    { label: '变更待审批', value: 3 },
    { label: '变更审批通过', value: 4 },
    { label: '变更审批拒绝', value: 5 }
  ],
  collectionCompanyType: [
    { label: '资金方', value: 'Capitap' },
    { label: '融资企业', value: 'Customer' }
  ],
  contractType: [
    { label: '授信合同', value: 1 },
    { label: '贷前合同', value: 2 }
  ],
  authenticRightFlag: [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ],
  allFinishState: [
    { label: '待签署', value: 0 },
    { label: '部分签署', value: 2 },
    { label: '待生效', value: 3 },
    { label: '已签署', value: 1 },
    { label: '已作废', value: 4 }
  ],
  loanAccStatus: [
    { label: '正常', value: '1' },
    { label: '已结清', value: '2' }
  ],
  week: [
    { label: '仅一次', value: 0, disabled: false },
    { label: '星期一', value: 1, disabled: false },
    { label: '星期二', value: 2, disabled: false },
    { label: '星期三', value: 3, disabled: false },
    { label: '星期四', value: 4, disabled: false },
    { label: '星期五', value: 5, disabled: false },
    { label: '星期六', value: 6, disabled: false },
    { label: '星期天', value: 7, disabled: false }
  ],
  quotaApplyStatus: [
    { label: '保存', value: 0 },
    { label: '申请', value: 1 },
    { label: '通过', value: 2 },
    { label: '驳回', value: 3 },
    { label: '关闭', value: 4 },
    { label: '失效', value: 5 }
  ],
  auditingState: [
    { label: '待审批', value: '0' },
    { label: '审批通过', value: '1' },
    { label: '审批拒绝', value: '2' }
  ],
  payStatus: [
    { label: '未付款', value: 0 },
    { label: '部分付款', value: 1 },
    { label: '已付款', value: 2 }
  ],
  useStatus: [
    { label: '未使用', value: 0 },
    { label: '已使用', value: 1 }
  ],
  newsStatus: [
    { label: '启动', value: 1 },
    { label: '停止', value: 0 }
  ],
  newsType: [
    { label: '通知', value: 1 },
    { label: '业务', value: 2 }
  ],
  initType: [
    { label: '其他', value: 0 },
    { label: '授信', value: 1 },
    { label: '用信', value: 2 },
    { label: '还款', value: 3 },
    { label: '合同', value: 4 },
    { label: '额度', value: 5 },
    { label: '准入', value: 6 }
  ],
  messageType: [
    { label: '平台消息', value: '0' },
    { label: '业务消息', value: '1,2,3,4,5,6' }
  ],
  accountUserType: [
    { label: '企业账户', value: '12' },
    { label: '个人账户', value: '11' },
    { label: '个体工商账户', value: '13' }
  ],
  userType: [
    { label: '个人用户', value: '10' },
    { label: '个体工商用户', value: '15' },
    { label: '企业用户', value: '20' },
    { label: '匿名用户', value: '40' }
  ],
  eJz4692OperationType: [
    { label: '收入', value: '10' },
    { label: '支出', value: '20' },
    { label: '冻结', value: '30' },
    { label: '冲正', value: '40' },
    { label: '在途转账', value: '50' },
    { label: '解冻', value: '60' }
  ],
  personalType: [
    { label: '企业法定代表人', value: '12' },
    { label: '经办人', value: '11' }
  ],
  firmScaleType: [
    { label: '大型', value: '01' },
    { label: '中型', value: '02' },
    { label: '小型', value: '03' },
    { label: '微型', value: '04' },
    { label: '其他', value: '98' }
  ],
  bankAccountType: [
    { label: '个人账户', value: '11' },
    { label: '企业账户', value: '12' }
  ]
}
export default constants
