import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultLayout from '../layout/default'
import simpleLayout from '../layout/simple'
import store from '../store/index'
import api from '../assets/js/api'
import notFund from '../views/404'
import notAuth from '../views/401'
import system from './system'
import company from './company'
import admittance from './admittance'
import assets from './assets'
import risk from './risk'
import quota from './quota'
import product from './product'
import contract from './contract'
import credit from './credit'
import use from './use'
import workbench from './workbench'
import payBack from './payBack'
import serviceCharge from './serviceCharge'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    component: defaultLayout,
    children: [
      {
        path: '',
        name: 'login',
        meta: {
          title: '登录',
          showPageTitle: true
        },
        component: () => import('../views/login')
      },
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          showPageTitle: true
        },
        component: () => import('../views/index')
      },
      ...system,
      ...company,
      ...admittance,
      ...assets,
      ...risk,
      ...quota,
      ...product,
      ...contract,
      ...credit,
      ...use,
      ...workbench,
      ...payBack,
      ...serviceCharge
    ]
  },
  {
    path: '/login',
    component: simpleLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          requireAuth: false,
          title: '登录'
        },
        component: () => import('@/views/login')
      },
      {
        path: '/findPassWord',
        name: 'findPassWord',
        meta: {
          requireAuth: false,
          title: '找回密码'
        },
        component: () => import('@/views/findPassWord')
      },
      {
        path: '/cfcaDownload',
        name: 'cfcaDownload',
        meta: {
          requireAuth: false,
          title: '控件下载'
        },
        component: () => import('@/views/cfcaDownload')
      }]
  },
  {
    path: '/personal',
    component: defaultLayout,
    children: [{
      path: '',
      meta: {
        requireAuth: false,
        title: '个人中心'
      },
      component: () => import('@/views/personal')
    }]
  },
  {
    path: '/editMobile',
    component: defaultLayout,
    children: [{
      path: '',
      meta: {
        requireAuth: false,
        title: '更改手机号'
      },
      component: () => import('@/views/editMobile')
    }]
  },
  {
    path: '/401',
    component: simpleLayout,
    children: [{
      path: '',
      meta: {
        requireAuth: false
      },
      component: notAuth
    }]
  },
  {
    path: '*',
    component: simpleLayout,
    children: [{
      path: '',
      meta: {
        requireAuth: false
      },
      component: notFund
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 修改每个页面的导航标题
  if (Object.prototype.hasOwnProperty.call(to.params, 'name')) {
    to.meta.title = to.params.title
  }
  store.commit('changeMenu', to.path)
  if (to.meta.requireAuth) {
    if (store.state.token) {
      // 获取功能权限列表
      if (!store.state.featureList || store.state.featureList.length === 0) {
        api.base.getFeature().then(result => {
          if (result.data.data && result.data.data.length > 0) {
            const featureList = result.data.data
            window.sessionStorage.setItem('featureList', JSON.stringify(featureList))
            store.commit('updateFeatureList', featureList)
          }
          next()
        }).catch(e => {
          next()
        })
      } else {
        next()
      }
    } else {
      window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
    }
  } else {
    next()
  }
})
export default router
