/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/systemManagement/role',
    name: 'systemManagementRole',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '角色列表'
    },
    component: () => import('@/views/systemManagement/role/list')
  },
  // 设置角色员工
  {
    path: '/systemManagement/role/roleSetting',
    name: 'roleRoleSetting',
    meta: {
      requireAuth: false,
      group: '角色列表',
      parentName: 'systemManagementRole',
      title: '设置人员'
    },
    component: () => import('@/views/systemManagement/role/roleSetting')
  },
  // 设置角色菜单
  {
    path: '/systemManagement/role/setFunction',
    name: 'roleSetFunction',
    meta: {
      requireAuth: false,
      group: '角色列表',
      parentName: 'systemManagementRole',
      title: '设置菜单'
    },
    component: () => import('@/views/systemManagement/role/setFunction')
  },
  {
    path: '/systemManagement/employee',
    name: 'systemManagementEmployee',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '用户列表'
    },
    component: () => import('@/views/systemManagement/employee/list')
  },
  {
    path: '/systemManagement/employee/detail',
    name: 'systemManagementEmployeeDetail',
    meta: {
      requireAuth: false,
      group: '用户列表',
      parentName: 'systemManagementEmployee',
      title: '详情'
    },
    component: () => import('@/views/systemManagement/employee/edit')
  },
  {
    path: '/systemManagement/employee/:editMode',
    name: 'systemManagementEmployeeEdit',
    meta: {
      requireAuth: false,
      group: '用户列表',
      parentName: 'systemManagementEmployee',
      title: ''
    },
    component: () => import('@/views/systemManagement/employee/edit')
  },
  {
    // 操作日志
    path: '/systemManagement/workflow',
    name: 'workflowList',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '工作流管理'
    },
    component: () => import('@/views/systemManagement/workflow/list')
  },
  {
    path: '/systemManagement/workflow/edit',
    name: 'systemWorkflowEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'workflowList',
      title: '工作流编辑'
    },
    component: () => import('@/views/systemManagement/workflow/edit')
  },
  {
    path: '/systemManagement/workflow/config',
    name: 'systemWorkflowConfig',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'workflowList',
      title: '工作流配置'
    },
    component: () => import('@/views/systemManagement/workflow/config')
  },
  {
    path: '/systemManagement/account',
    name: 'account',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '电子账薄管理'
    },
    component: () => import('@/views/systemManagement/account/index')
  },
  {
    path: '/systemManagement/account/detail',
    name: 'accountDetail',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '详情'
    },
    component: () => import('@/views/systemManagement/account/detail')
  },
  {
    path: '/systemManagement/account/bindingEdit',
    name: 'bindingBankEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '绑定银行卡'
    },
    component: () => import('@/views/systemManagement/account/binding-bank')
  },
  {
    path: '/systemManagement/account/binding1Edit',
    name: 'bindingBank1Edit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '绑定银行卡'
    },
    component: () => import('@/views/systemManagement/account/binding-bank1')
  },
  {
    path: '/systemManagement/account/verificationOrPay',
    name: 'verificationOrPay',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '验证'
    },
    component: () => import('@/views/systemManagement/account/verification-or-pay')
  },
  {
    path: '/systemManagement/account/verificationOrPay1',
    name: 'verificationOrPay1',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '验证'
    },
    component: () => import('@/views/systemManagement/account/verification-or-pay1')
  },
  {
    path: '/systemManagement/account/waitFor',
    name: 'waitFor',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '等待中'
    },
    component: () => import('@/views/systemManagement/account/wait-for')
  },
  {
    path: '/systemManagement/account/:editMode',
    name: 'accountEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: ''
    },
    component: () => import('@/views/systemManagement/account/edit')
  }
]
