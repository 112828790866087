import axios from './http'

const api = {
  base: {
    // 登录
    goLogin: data => axios.put('/app/b/v1/user/login/menu/feature', data),
    // 退出登录
    logOut: () => axios.put('/app/b/v1/user/logout'),
    // 获取菜单
    getMenu: () => axios.get('/app/b/v1/user/menu/feature/list'),
    // 获取全部功能
    getFeature: () => axios.get('/app/b/v1/user/feature/alllist'),
    // 获取验证码图片
    getImg: () => axios.get('/app/b/v1/user/login/get/image/code'),
    // platform 注册获取验证码
    getVerify: params => axios.get('/platform/partner-basic/sendMsg', { params: params })
  },
  open: {
    downloadP: data => axios.post('/channel/open/downloadP', data, { responseType: 'blob'}),
    sendEmailCode: (email, type) => axios.post(`/channel/open/sendEmailCode?email=${email}&type=${type}`),
    sendSmsCode: data => axios.post('/channel/open/sendCustomSmsCode', data),
    sendCustomSmsCode: (mobile, type, password) => axios.post(`/channel/open/sendCustomSmsCode?mobile=${mobile}&type=${type}&password=${password}`),
    validPhoneCode: (mobile, smsCode, type) => axios.post(`/channel/open/validPhoneCode?mobile=${mobile}&smsCode=${smsCode}&type=${type}`),
    forget: data => axios.post('/channel/open/forget', data),
    doContractAndSign: data => axios.post('/channel/open/doContractAndSign', data),
    sign005: data => axios.post('/channel/open/sign005', data),
    verifyPKCS7: data => axios.post('/channel/open/verifyPKCS7', data),
    forgetEmail: (phone, email, emailCode, newPassword, confirmNewPassword ) => axios.post(`/channel/open/forgetEmail?phone=${phone}&email=${email}&emailCode=${emailCode}&newPassword=${newPassword}&confirmNewPassword=${confirmNewPassword}`),
  },
  event: {
    getListPage: (act, params) => axios.get('/channel/b/p/event/page?act=' + act, { params: params }),
    // 待办
    waitTodoList: params => axios.get('/channel/b/p/event/page/1', { params: params }),
    // 已办
    completeTodoList: params => axios.get('/channel/b/p/event/page/2', { params: params }),
    getEventList: params => axios.get('/channel/b/p/event/type/list', { params: params })
  },
  workbench: {
    pageList: params => axios.get('/channel/b/p/information/listPage', { params: params }),
    countMsg: params => axios.get('/channel/b/p/information/countMsg', { params: params }),
    readAll: data => axios.put('/channel/b/p/information/readAll', data),
    readById: id => axios.put('/channel/b/p/information/readById/' + id),
    workbenchDetail: id => axios.get('/channel/b/p/information/detailById/' + id)
  },
  company: {
    channel: {
      addChannel:data=>axios.post('/channel/b/p/channel/add', data),
      disable:channelId=>axios.post('/channel/b/p/channel/disable/' + channelId),
      enable:channelId=>axios.post('/channel/b/p/channel/enable/' + channelId),
      pageList:params =>axios.get('/channel/b/p/channel/listPage', { params: params }),
      getChannelList:params =>axios.get('/channel/b/p/channel/list', { params: params }),
      getChannelDetail:params => axios.get('/channel/b/p/channel/detail', { params: params }),
      updateChannel:data=>axios.post('/channel/b/p/channel/update', data),
      getNotChannelOfCore: params => axios.get('/channel/b/p/channel/getNotChannelOfCore', { params: params }),
      setCore: data => axios.post('/channel/b/p/channel/setCore', data)
    },
    capital: {
      addCoreCapAmount: (coreCode, data) => axios.post('/channel/b/p/core/cap/amount/add?coreCode=' + coreCode, data),
      removeCoreCapAmount: (coreCode, relId) => axios.post('/channel/b/p/core/cap/amount/remove?coreCode=' + coreCode + '&relId=' + relId),
      addCoreCapProd: (coreCode, data) => axios.post('/channel/b/p/core/cap/product/add?coreCode=' + coreCode, data),
      removeCoreCapProd: (coreCode, relId) => axios.post('/channel/b/p/core/cap/product/remove?coreCode=' + coreCode + '&relId=' + relId),
      pageList: params => axios.get('/channel/b/p/cap/page', { params: params }),
      getCapList: params => axios.get('/channel/b/p/cap/list', { params: params }),
      addCap: data => axios.post('/channel/b/p/cap/add', data),
      getCapDetail: params => axios.get('/channel/b/p/cap/details', { params: params }),
      getCapChildLevel: capId => axios.get('/channel/b/p/cap/details/parentChildLevel?capId=' + capId),
      capAlt: data => axios.post('/channel/b/p/cap/alt', data),
      enableCap: (capCode, enable) => axios.post('/channel/b/p/cap/enable?capCode=' + capCode + '&enable=' + enable),
      cancelCapAdmin: data => axios.post('/channel/b/p/cap/user/admin/cancel', data),
      setCapAdmin: data => axios.post('/channel/b/p/cap/user/admin/set', data),
      getUserList: params => axios.get('/channel/b/p/cap/user/list', { params: params }),
      getCapUserPage: params => axios.get('/channel/b/p/cap/user/page', { params: params }),
      getFirmCityList: params => axios.get('/channel/b/p/cap/details/firmCityList', { params: params }),
      firmCitySave: (capId, data) => axios.post('/channel/b/p/cap/details/firmCitySave?capId=' + capId, data),
      getCreditPage: params => axios.get('/channel/b/p/cap/details/creditPage', { params: params }),
      getCoreCapProductList: params => axios.get('/channel/b/p/product/list_info', { params: params }),
    },
    core: {
      pageList: params => axios.get('/channel/b/p/core/page', { params: params }),
      getCoreList: params => axios.get('/channel/b/p/core/list', { params: params }),
      getCoreDetail: coreId => axios.get(`/channel/b/p/core/detail/${coreId}`),
      addCore: data => axios.post('/channel/b/p/core/add', data),
      updateEnable: (coreCode, enable) => axios.post(`/channel/b/p/core/enable?coreCode=${coreCode}&enable=${enable}`),
      updateCore: data => axios.post('/channel/b/p/core/update', data),
    },
    customer: {
      addCustomer: data => axios.post('/channel/b/p/customer/add', data),
      updateCustomer: data => axios.post('/channel/b/p/customer/alt', data),
      getCustomerList: params => axios.get('/channel/b/p/customer/list', { params: params }),
      pageList: params => axios.get('/channel/b/p/customer/page', { params: params }),
      exportFile: data => axios.post('/channel/b/p/customer/export/customerList', data, { responseType: 'blob' }),
      getListBlack: params => axios.get('/channel/b/p/customer/listBlack', { params: params }),
      getCustomerDetail: params => axios.get('/channel/b/p/customer/details', { params: params }),
      getCustomerUserPage: params => axios.get('/channel/b/p/customer/user/page', { params: params }),
      enableCustomer: (customerCode, enable) => axios.post('/channel/b/p/customer/enable?customerCode=' + customerCode + '&enable=' + enable)
    },
  },
  admittance: {
    addAccessUser: data => axios.post('/channel/b/p/firm/access/addAccessUser', data),
    updateAccessUser: data => axios.post('/channel/b/p/firm/access/updateAccessUser', data),
    getAccessUserDetail: id => axios.get('/channel/b/p/firm/access/detailAccessUser/' + id),
    licenseFileUploadUrl: data => axios.post('/channel/b/p/access/info/uploadOfLicense', data),
    idCardFileZUploadUrl: data => axios.post('/channel/b/p/access/info/uploadOfLicense?side=0', data),
    idCardFileFUploadUrl: data => axios.post('/channel/b/p/access/info/uploadOfLicense?side=1', data),
    getAccessUser: params => axios.get('/channel/b/p/firm/access/getAccessUser', { params: params }),
    getNotAccessFirm: appKey => axios.get('/channel/b/p/firm/access/getNotAccessFirm?appKey=' + appKey),
    getFirmByAppKey: appKey => axios.get('/channel/b/p/firm/getFirmByAppKey?appKey=' + appKey),
    getNotAccessUser: params => axios.get('/channel/b/p/firm/access/getNotAccessUser', { params: params }),
    getFirmData: firmId => axios.get('/channel/b/p/firm/check/getFirmData?firmId=' + firmId),
    getFirmDetail: firmId => axios.get('/channel/b/p/firm/check/getFirmDetails?firmId=' + firmId),
    getFirmInspec: firmId => axios.get('/channel/b/p/firm/check/getFirmInspect?firmId=' + firmId),
    getFirmQuota: firmId => axios.get('/channel/b/p/firm/check/getFirmQuota?firmId=' + firmId),
    commitFirmInfo: firmId => axios.post('/channel/b/p/access/info/commitFirmInfo?firmId=' + firmId),
    saveBasicsFirm: data => axios.post('/channel/b/p/access/info/saveBasicsFirm', data),
    // 企业资料文件数据
    firmFileList: (firmId, type) => axios.get(`/channel/b/p/access/info/firmFileList?firmId=${firmId}&type=${type}`),
    getInfoFirmDetails: firmId => axios.get('/channel/b/p/access/info/getFirmDetails?firmId=' + firmId),
    saveFirmInspect: data => axios.post('/channel/b/p/firm/check/saveFirmInspect', data),
    complete: (taskId, data) => axios.post('/channel/b/p/firm/check/complete?taskId=' + taskId, data),
    saveFirmQuota: data => axios.post('/channel/b/p/firm/check/saveFirmQuota', data),
    commitCheck: data => axios.post('/channel/b/p/firm/check/commitCheck', data),
    files: {
      listFile: params => axios.get('/channel/b/p/firm/file/listFile', { params: params }),
      uploadFile: (firmId, data) => axios.post('/channel/b/p/firm/file/uploadFile?firmId=' + firmId, data),
      deleteFile: fileId => axios.delete('/channel/b/p/firm/file/deleteFile?fileId=' + fileId),
    },
    // 风控
    risk: {
      getReportForRemote: firmId => axios.post('/channel/b/p/firm/check/getReportForRemote?firmId=' + firmId),
      getRiskReportList: firmId => axios.post('/channel/b/p/firm/check/getRiskReportList?firmId=' + firmId),
      testRisk: param => axios.get('/channel/open/test/risk?param=' + param )
    }
  },
  assets: {
    invoice: {
      // 获取发票信息列表
      listPage: params => axios.get('/channel/b/p/firm/invoice/list', { params: params }),
      invoiceDetail: id => axios.get('/channel/b/p/firm/invoice/details?id=' + id),
      confirm: data => axios.post('/channel/b/p/firm/invoice/confirm', data),
      invoiceAuthBatch: (invoiceKind, data) => axios.post('/channel/b/p/eaccount/InvoiceAuthBatch?invoiceKind=' + invoiceKind, data)
    },
    contract: {
      listPage: params => axios.get('/channel/b/p/contract/base/getContractList', { params: params }),
      contractDetail: contractId => axios.get('/channel/b/p/contract/base/details/' + contractId),
      detailsForReceivablePayable: contractId => axios.get('/channel/b/p/contract/base/detailsForReceivablePayable/' + contractId),
    },
    receivable: {
      listPage: params => axios.get('/channel/b/p/receivablePayableInfo/getReceivablePayableInfoList', { params: params }),
      receivableDetail: id => axios.get('/channel/b/p/receivablePayableInfo/details/' + id),
    },
  },
  risk: {
    black: {
      pageList: params => axios.get('/channel/b/p/rick/blackPage', { params: params }),
      getBlackList: params => axios.get('/channel/b/p/rick/listBlack', { params: params }),
      auditBlack: (id, state, reason) => axios.post(`/channel/b/p/rick/auditBlack?id=${id}&state=${state}&reason=${reason}`),
      moveInBlack: (id, reason) => axios.post(`/channel/b/p/rick/moveInBlack?id=${id}&reason=${reason}`)
    },
    white: {
      pageList: params => axios.get('/channel/b/p/rick/whitePage', { params: params }),
      getWhiteList: params => axios.get('/channel/b/p/rick/listWhite', { params: params }),
      auditWhite: (id, state, reason) => axios.post(`/channel/b/p/rick/auditWhite?id=${id}&state=${state}&reason=${reason}`),
      moveInWhite: (id, reason) => axios.post(`/channel/b/p/rick/moveInWhite?id=${id}&reason=${reason}`),
      deleteWhite: id => axios.delete('/channel/b/p/rick/delete?id=' + id)
    }
  },
  product: {
    model: {
      pageList: params => axios.get('/channel/b/p/product/listModel', { params: params }),
      detailModel: params => axios.get('/channel/b/p/product/detailModel', { params: params }),
      altModel: data => axios.post('/channel/b/p/product/altModel', data),
      addModel: data => axios.post('/channel/b/p/product/addModel', data),
      delModel: id => axios.get('/channel/b/p/product/delModel?id=' + id),
      altStatus: (id, state, opinion) => axios.get(`/channel/b/p/product/altStatus?id=${id}&state=${state}&opinion=${opinion}`),
      altState: (id, state) => axios.get(`/channel/b/p/product/altState?id=${id}&state=${state}`),
      listModelProduct: params => axios.get('/channel/b/p/product/listModelProduct', { params: params }),
    },
    product: {
      // 金融产品列表
      pageList: params => axios.get('/channel/b/p/product/page?type=1', { params: params }),
      // 金融产品列表
      productPageList: params => axios.get('/channel/b/p/cap/productPage?type=1', { params: params }),
      add: data => axios.post('/channel/b/p/product/add', data),
      update: data => axios.post('/channel/b/p/product/update', data),
      productDel: productCode => axios.get('/channel/b/p/product/del?productCode=' + productCode),
      altProductStatus: (productCode , state, opinion) => axios.get(`/channel/b/p/product/altProductStatus?productCode=${productCode }&state=${state}&opinion=${opinion}`),
      altProductState: (productCode , String) => axios.get(`/channel/b/p/product/altProductState?productCode=${productCode}&String=${String}`),
      getDetail: params => axios.get('/channel/b/p/product/details', { params: params }),
      detailsHistory: params => axios.get('/channel/b/p/product/detailsHistory', { params: params }),
      // 随借随还产品列表
      getListRel: params => axios.get('/channel/b/p/product/list_rel', { params: params })
    },
    sceneSide: {
      // 金融产品列表
      pageList: params => axios.get('/channel/b/p/product/page?type=2', { params: params }),
      addScene: data => axios.post('/channel/b/p/product/addScene', data),
      alSceneStatus: (productCode , state, opinion) => axios.get(`/channel/b/p/product/alSceneStatus?productCode=${productCode}&state=${state}&opinion=${opinion}`),
      altScene: data => axios.post('/channel/b/p/product/altScene', data),
    }
  },
  quota: {
    customer: {
      pageList: params => axios.get('/channel/b/p/quota/quotaPage', { params: params }),
      quotaPageSum: params => axios.get('/channel/b/p/quota/quotaPageSum', { params: params }),
      // 导出
      exportFile: params => axios.get('/channel/b/p/quota/export',{ params, responseType: 'blob' }),
    },
    core: {
      addQuota: data => axios.post('/channel/b/p/quotaApply/add', data),
      quotaDetail: id => axios.get('/channel/b/p/quotaApply/detail?id=' + id),
      amountListPage: params => axios.get('/channel/b/p/quotaApply/listAmountPage', { params: params }),
      pageList: params => axios.get('/channel/b/p/quotaApply/listPage', { params: params }),
    }
  },
  contract: {
    template: {
      pageList: params => axios.get('/channel/b/p/contract/template/listPage', { params: params }),
      getTemplateCodeList: data => axios.post('/channel/b/p/contract/template/codeList', data),
      contractDetails: contractId => axios.get('/channel/b/p/contract/template/getInfoById/' + contractId ),
      getTemplateList: params => axios.get('/channel/b/p/contract/template/list', { params: params }),
      addTemplate: data => axios.post('/channel/b/p/contract/template/add', data),
      altStatus: (id , state, opinion) => axios.get(`/channel/b/p/contract/template/altStatus?id=${id}&state=${state}&opinion=${opinion}`),
      updateTemplate: data => axios.post('/channel/b/p/contract/template/update', data),
    },
    sign: {
      getDetail: contractId => axios.get('/channel/b/p/contract/produce/details/' + contractId ),
      altOfflineSign: data => axios.post('/channel/b/p/contract/produce/altOfflineSign', data),
      pageList: params => axios.get('/channel/b/p/contract/produce/signList', { params: params }),
    },
    isContractSign: params => axios.get('/channel/b/p/contract/produce/isContractSign', { params: params }),
  },
  cfcaSign: {
    isContractAccount: params => axios.get('/channel/b/p/cfcaSign/isContractAccount', { params: params }),
    accountDetail: params => axios.get('/channel/b/p/cfcaSign/accountDetail', { params: params }),
    addContractAccount: data => axios.post('/channel/b/p/cfcaSign/add', data),
    faceAuth: data => axios.post('/channel/b/p/cfcaSign/faceAuth', data),
    sign: data => axios.post('/channel/b/p/cfcaSign/sign', data),

    altOfflineSign: data => axios.post('/channel/b/p/cfcaSign/altOfflineSign', data),
    signSmsSend: params => axios.get('/channel/b/p/cfcaSign/signSmsSend', { params: params }),
    signFaceAuth: data => axios.post('/channel/b/p/cfcaSign/signFaceAuth', data),
    signFaceCheck: contractId => axios.get('/channel/b/p/cfcaSign/signFaceCheck?contractId=' + contractId),
    accountSmsSend: params => axios.get('/channel/b/p/cfcaSign/accountSmsSend', { params: params }),
    accountSmsValidCode: validCode => axios.get('/channel/b/p/cfcaSign/accountSmsValidCode?validCode=' + validCode),
    responseTrue: params => axios.get('/channel/b/p/cfcaSign/tx2319ResponseTrue', { params: params }),
  },
  credit: {
    pageList: params => axios.get('/channel/b/p/credit/page', { params: params }),
    complete: (taskId, data) => axios.post('/channel/b/p/work/creditComplete?taskId=' + taskId, data),
    creditDetails: params => axios.get('/channel/b/p/credit/details', { params: params }),
    // 导出
    exportFile: params => axios.get('/channel/b/p/credit/export',{ params, responseType: 'blob' }),
  },
  use: {
    pageList: params => axios.get('/channel/b/p/useApply/page', { params: params }),
    useDetails: params => axios.get('/channel/b/p/useApply/details', { params: params }),
    complete: (taskId, data) => axios.post('/channel/b/p/work/useComplete?taskId=' + taskId, data),
    // 导出
    exportFile: params => axios.get('/channel/b/p/useApply/export',{ params, responseType: 'blob' }),
    // 根据code导出
    theCodeExportFile: useCode => axios.get('/channel/b/p/useApply/exportDetails?useCode=' + useCode,{ responseType: 'blob' })
  },
  eAccount: {
    getAccountInfoByNumber: eAccountNumber => axios.get('/channel/b/p/eaccount/getAccountInfoByNumber?eAccountNumber=' + eAccountNumber),
  },
  payBack: {
    exportFile: params => axios.get('/channel/b/p/repayment/export',{ params, responseType: 'blob' }),
    pageList: params => axios.get('/channel/b/p/repayment/page', { params: params }),
    payBackDetails: repaymentCode => axios.get('/channel/b/p/repayment/details?repaymentCode=' + repaymentCode),
    getRecordPage: params => axios.get('/channel/b/p/repayment/recordPage', { params: params }),
    getTrial: params => axios.get('/channel/b/p/repayment/getTrial', { params: params }),
    // 单期
    exportRecord: repaymentCode => axios.get('/channel/b/p/repayment/exportRecord?repaymentCode=' + repaymentCode,{ responseType: 'blob' }),
    listRecord: params => axios.get('/channel/b/p/repayment/listRecord', { params: params }),
    recordDetails: recordId => axios.get('/channel/b/p/repayment/recordDetails?recordId=' + recordId),
    // 多期
    exportPlan: repaymentCode => axios.get('/channel/b/p/repayment/exportPlan?repaymentCode=' + repaymentCode,{ responseType: 'blob' }),
    listPlan: params => axios.get('/channel/b/p/repayment/listPlan', { params: params }),
    listRecordDto: planId => axios.get('/channel/b/p/repayment/listRecordDto?planId=' + planId),
  },
  warehouse: {
    pageList: params => axios.get('/channel/b/p/warehouse/getWarehouseMasterInfoList', { params: params }),
    warehouseDetail: params => axios.get('/channel/b/p/warehouse/getWarehouseDetail', { params: params })
  },
  // 服务费管理
  serviceCharge:{
    // 服务费模板列表
    getTemplatePage: params => axios.get('/channel/b/p/serviceCharge/template/listPage', { params: params }),
    // 服务费模板详情
    getTemplateDetail: params => axios.get('/channel/b/p/serviceCharge/template/detail', { params: params }),
    // 添加服务费模板
    addTemplate: data => axios.post('/channel/b/p/serviceCharge/template/add', data),
    //根据核心企业加载运营方
    getChannelByCore: params => axios.get('/channel/b/p/serviceCharge/template/getChannelByCoreId', { params: params }),
    // 修改服务费模板
    updateTemplate: data => axios.post('/channel/b/p/serviceCharge/template/update', data),
    // 服务费明细列表
    getDetailPage: params => axios.get('/channel/b/p/serviceCharge/details/listPage', { params: params }),
    // 根据月份获取服务费明细列表
    getByMonth: params => axios.get('/channel/b/p/serviceCharge/details/getByMonth', { params: params }),
    // 服务费明细详情
    getDetailInfo: params => axios.get('/channel/b/p/serviceCharge/details/getInfoById', { params: params }),
    // 核销
    verify: (id, verifyUrl) => axios.post(`/channel/b/p/serviceCharge/details/verify?id=${id}&verifyUrl=${verifyUrl}`),
  },
  aluminum: {
    pageList: params => axios.get('/channel/b/p/aluminum/getList', { params: params }),
    addAluminum: price => axios.put('/channel/b/p/aluminum/add?price=' + price)
  },
  // 仪表盘
  dashboard: {
    // 统计
    count: params => axios.get('/channel/b/p/dashboard/count', { params: params }),
    getListSummary: params => axios.get('/channel/b/p/quota/listSummary', { params: params }),
    // 放款分析
    loanAssayChart: params => axios.get('/channel/b/p/dashboard/loanAssayChart', { params: params }),
    // 放款分析
    loanAmountChart: params => axios.get('/channel/b/p/dashboard/loanAmountChart', { params: params }),
    // 额度汇总
    coreQuotaChart: params => axios.get('/channel/b/p/dashboard/coreQuotaChart', { params: params }),
    customerQuotaChart: params => axios.get('/channel/b/p/dashboard/customerQuotaChart', { params: params }),
  },
  system: {
    employee: {
      userPage: params => axios.get('/channel/b/p/sys-user/page', { params: params }),
      userList: params => axios.get('/channel/b/p/sys-user/list', { params: params }),
      updatePersonalCenter: data => axios.post('/channel/b/p/sys-user/update/personal/center', data),
      addAdminToFirm: (firmCode, employeeCode) => axios.put(`/channel/b/p/sys-user/addAdminToFirm?firmCode=${firmCode}&employeeCode=${employeeCode}`),
      cancelAdminToFirm: (firmCode, employeeCode) => axios.put(`/channel/b/p/sys-user/cancelAdminToFirm?firmCode=${firmCode}&employeeCode=${employeeCode}`),
      allByRole: (appKey, roleCode) => axios.get(`/channel/b/p/sys-user/list/all/by/role?appKey=${appKey}&roleCode=${roleCode}`),
      addUser: data => axios.post('/channel/b/p/sys-user/add', data),
      enable: employeeCode => axios.put('/channel/b/p/sys-user/user/enable?employeeCode=' + employeeCode),
      disable: employeeCode => axios.put('/channel/b/p/sys-user/user/disable?employeeCode=' + employeeCode),
      detail: params => axios.get('/channel/b/p/sys-user/detail', { params: params }),
      firmList: params => axios.get('/channel/b/p/sys-user/firmList', { params: params }),
      getPersonal: params => axios.get('/channel/b/p/sys-user/personal/center', { params: params }),
      updatePassword: data => axios.post('/channel/b/p/sys-user/updatePassword', data),
      updatePhone: (phone, smsCode) => axios.post(`/channel/b/p/sys-user/updatePhone?phone=${phone}&smsCode=${smsCode}`),
      update: data => axios.post('/channel/b/p/sys-user/update', data)
    },
    role: {
      findRolePage: params => axios.get('/channel/b/p/sys-role/role/find/by/employee/page', { params: params }),
      rolePage: params => axios.get('/channel/b/p/sys-role/find/page', { params: params }),
      addRelation: data => axios.post('/channel/b/p/sys-role/role/add/and/relation', data),
      add: data => axios.post('/channel/b/p/sys-role/add', data),
      appRole: (appKey, roleCode, data) => axios.post('/channel/b/p/sys-role/add/relation/to/role?appKey=' + appKey + '&roleCode=' + roleCode, data),
      delete: (appKey, roleCode) => axios.delete(`/channel/b/p/sys-role/delete?appKey=${appKey}&roleCode=${roleCode}`),
      findRole: firmCode => axios.get('/channel/b/p/sys-role/findRole?firmCode=' + firmCode),
      featureList: (appKey, roleCode) => axios.get(`/channel/b/p/sys-role/menu/feature/list?appKey=${appKey}&roleCode=${roleCode}`),
      featureSet: (appKey, roleCode, data) => axios.post('/channel/b/p/sys-role/menu/feature/set?appKey=' + appKey + '&roleCode=' + roleCode, data),
      deleteEmployee: (appKey, roleCode, employeeCode) => axios.delete(`/channel/b/p/sys-role/role/delete?appKey=${appKey}&roleCode=${roleCode}&employeeCode=${employeeCode}`),
      update: data => axios.put('/channel/b/p/sys-role/update', data)
    },
    org: {
      add: data => axios.post('/channel/b/p/sys-org/add', data),
      delete: orgCode => axios.delete('/channel/b/p/sys-org/delete?orgCode=' + orgCode),
      addEmployee: (orgCode , data) => axios.put('/channel/b/p/sys-org/employee/add/list?orgCode=' + orgCode, data),
      listEmployee: orgCode => axios.get('/channel/b/p/sys-org/employee/list?orgCode=' + orgCode),
      removeEmployee: (orgCode, employeeCode) => axios.put(`/channel/b/p/sys-org/employee/remove?orgCode=${orgCode}&employeeCode=${employeeCode}`),
      list: params => axios.get('/channel/b/p/sys-org/list', { params: params }),
      update: data => axios.post('/channel/b/p/sys-org/update', data)
    },
    menu: {
      menuList: params => axios.get('/channel/b/p/sys-menu/all/feature/list', { params: params })
    },
    information: {
      pageList: params => axios.get('/channel/b/p/information/template/listPage', { params: params }),
      getDetail: id => axios.get('/channel/b/p/information/template/details?id=' + id),
      add: data => axios.post('/channel/b/p/information/template/add', data),
      disable: id => axios.post('/channel/b/p/information/template/disable?id=' + id),
      enable: id => axios.post('/channel/b/p/information/template/enable?id=' + id),
      deleteTemplate: id => axios.delete('/channel/b/p/information/template/delete?id=' + id),
      initPageList: params => axios.get('/channel/b/p/information/init/listPage', { params: params }),
      getInitDetail: informationCode => axios.get('/channel/b/p/information/init/details?informationCode=' + informationCode),
      initUpdate: data => axios.post('/channel/b/p/information/init/update', data),
    },
    account: {
      getAccountList: params => axios.get('/channel/b/p/eaccount/list', { params: params }),
      getTakeEffectList: params => axios.get('/channel/b/p/eaccount/listTakeEffect', { params: params }),
      addAccount: data => axios.post('/channel/b/p/eaccount/add', data),
      zjBankCodeList: params => axios.get('/channel/b/p/eaccount/zjBankCodeList', { params: params }),
      getAccountDetail: userId => axios.get('/channel/b/p/eaccount/info?userId=' + userId),
      updateAccount: data => axios.post('/channel/b/p/eaccount/update', data),
      getBankDetail: userId => axios.get('/channel/b/p/eaccount/infoAndBank?userId=' + userId),
      addBank: data => axios.post('/channel/b/p/eaccount/eBank/add', data),
      zhongUrl: params => axios.get('/channel/b/p/eaccount/zhongUrl', { params: params }),
      updateBank: data => axios.post('/channel/b/p/eaccount/eBank/update', data),
      eJz4611: userId => axios.post('/channel/b/p/eaccount/zhongjin/eJz4611?userId=' + userId),
      relieveEJz4611: (userId, operationFlag) => axios.post(`/channel/b/p/eaccount/zhongjin/eJz4611?userId=${userId}&operationFlag=${operationFlag}`),
      eJz4601: userId => axios.post('/channel/b/p/eaccount/zhongjin/eJz4601?userId=' + userId),
      eJz4613: (userId, verifyWay, amount, sMSCode) => axios.post(`/channel/b/p/eaccount/zhongjin/eJz4613?userId=${userId}&verifyWay=${verifyWay}&amount=${amount}&sMSCode=${sMSCode}`),
      eJz7703: userId => axios.post('/channel/b/p/eaccount/zhongjin/eJz7703?userId=' + userId),
      delAccount: userId => axios.post('/channel/b/p/eaccount/del?userId=' + userId),
      eJz4691: userId => axios.get('/channel/b/p/eaccount/zhongjin/eJz4691?userId=' + userId),
      eJz4692: params => axios.get('/channel/b/p/eaccount/zhongjin/eJz4692', { params: params }),
      eJz4643: (userId, amount) => axios.post(`/channel/b/p/eaccount/zhongjin/eJz4643?userId=${userId}&amount=${amount}`),
      eJz4641: (userId, amount, paymentWay, QRPaymentWay, pageURL) => axios.post(`/channel/b/p/eaccount/zhongjin/eJz4641?userId=${userId}&amount=${amount}&paymentWay=${paymentWay}&QRPaymentWay=${QRPaymentWay}&pageURL=${pageURL}`),
      eJz4665: (payeeUserID, bankAccountName, bankAccountNumber) => axios.post(`/channel/b/p/eaccount/zhongjin/eJz4665?payeeUserID=${payeeUserID}&bankAccountName=${bankAccountName}&bankAccountNumber=${bankAccountNumber}&operation=10`),
      getWhitePage: params => axios.get('/channel/b/p/eaccount/whitePage', { params: params }),
      eJz5011: data => axios.post('/channel/b/p/eaccount/zhongjin/eJz5011', data),
      tentAllList: params => axios.get('/channel/b/p/eaccount/tentAllList', { params: params }),
    },
    archives: {
      getFirmListDtoList: params => axios.get('/channel/b/p/file/firmListDtoList', { params: params }),
      getCoreDto: firmId => axios.get('/channel/b/p/file/coreDto?firmId=' + firmId),
      getCustomerOne: firmId => axios.get('/channel/b/p/file/customerOne?firmId=' + firmId),
      getCreditDto: creditCode => axios.get('/channel/b/p/file/creditDto?creditCode=' + creditCode),
      getUseDto: useCode  => axios.get('/channel/b/p/file/useDto?useCode=' + useCode),
      coreDownload: firmId => axios.get('/channel/b/p/file/coreDownload?firmId=' + firmId),
      firmDownload: firmId => axios.get('/channel/b/p/file/firmDownload?firmId=' + firmId),
      assetsCoreDownload: firmId => axios.get('/channel/b/p/file/assetsCoreDownload?firmId=' + firmId),
      assetsCoreDownloadBasic: firmId => axios.get('/channel/b/p/file/assetsCoreDownloadBasic?firmId=' + firmId),
      assetsCoreDownloadRe: firmId => axios.get('/channel/b/p/file/assetsCoreDownloadRe?firmId=' + firmId),
      assetsCoreDownloadInvoice: firmId => axios.get('/channel/b/p/file/assetsCoreDownloadInvoice?firmId=' + firmId),
      creditCoreBasicDownload: firmId => axios.get('/channel/b/p/file/creditCoreBasicDownload?firmId=' + firmId),
      useCoreReDownload: firmId => axios.get('/channel/b/p/file/useCoreReDownload?firmId=' + firmId),
      creditCoreInvoiceDownload: firmId => axios.get('/channel/b/p/file/creditCoreInvoiceDownload?firmId=' + firmId),
      creditCoreDownload: firmId => axios.get('/channel/b/p/file/creditCoreDownload?firmId=' + firmId),
      creditCoreConDownload: firmId => axios.get('/channel/b/p/file/creditCoreConDownload?firmId=' + firmId),
      creditCoreEncDownload: firmId => axios.get('/channel/b/p/file/creditCoreEncDownload?firmId=' + firmId),
      useCoreDownload: firmId => axios.get('/channel/b/p/file/useCoreDownload?firmId=' + firmId),
      useCoreContractDownload: firmId => axios.get('/channel/b/p/file/useCoreContractDownload?firmId=' + firmId),
      useCoreEnclosureDownload: firmId => axios.get('/channel/b/p/file/useCoreEnclosureDownload?firmId=' + firmId),
      useCoreReInvoiceDownload: firmId => axios.get('/channel/b/p/file/useCoreReInvoiceDownload?firmId=' + firmId),
      useCoreCollectionDownload: firmId => axios.get('/channel/b/p/file/useCoreCollectionDownload?firmId=' + firmId),
      useCoreLoanDownload: firmId => axios.get('/channel/b/p/file/useCoreLoanDownload?firmId=' + firmId),
      repaymentReCoDownload: firmId => axios.get('/channel/b/p/file/repaymentreCoDownload?firmId=' + firmId),
      repaymentCoreCoDownload: firmId => axios.get('/channel/b/p/file/repaymentCoreCoDownload?firmId=' + firmId),
      repaymentCorePayDownload: firmId => axios.get('/channel/b/p/file/repaymentCorePayDownload?firmId=' + firmId),
      customerDownload: firmId => axios.get('/channel/b/p/file/customerDownload?firmId=' + firmId),
      customerFirmDownload: firmId => axios.get('/channel/b/p/file/firmDownload?firmId=' + firmId),
      assetsCustomerDownload: firmId => axios.get('/channel/b/p/file/assetsCustomerDownload?firmId=' + firmId),
      assetsCustomerDownloadBasic: firmId => axios.get('/channel/b/p/file/assetsCustomerDownloadBasic?firmId=' + firmId),
      assetsCustomerDownloadRe: firmId => axios.get('/channel/b/p/file/assetsCustomerDownloadRe?firmId=' + firmId),
      assetsCustomerDownloadInvoice: firmId => axios.get('/channel/b/p/file/assetsCustomerDownloadInvoice?firmId=' + firmId),
      creditCustomerDownload: firmId => axios.get('/channel/b/p/file/creditCustomerDownload?firmId=' + firmId),
      creditCustomerConDownload: firmId => axios.get('/channel/b/p/file/creditCustomerConDownload?firmId=' + firmId),
      creditCustomerEncDownload: firmId => axios.get('/channel/b/p/file/creditCustomerEncDownload?firmId=' + firmId),
      creditCustomerBasicDownload: firmId => axios.get('/channel/b/p/file/creditCustomerBasicDownload?firmId=' + firmId),
      creditCustomerInvoiceDownload: firmId => axios.get('/channel/b/p/file/creditCustomerInvoiceDownload?firmId=' + firmId),
      useCustomerDownload: firmId => axios.get('/channel/b/p/file/useCustomerDownload?firmId=' + firmId),
      useCustomerContractDownload: firmId => axios.get('/channel/b/p/file/useCustomerContractDownload?firmId=' + firmId),
      useCustomerEnclosureDownload: firmId => axios.get('/channel/b/p/file/useCustomerEnclosureDownload?firmId=' + firmId),
      useCustomerReDownload: firmId => axios.get('/channel/b/p/file/useCustomerReDownload?firmId=' + firmId),
      useCustomerReInvoiceDownload: firmId => axios.get('/channel/b/p/file/useCustomerReInvoiceDownload?firmId=' + firmId),
      useCustomerVoucherDownload: firmId => axios.get('/channel/b/p/file/useCustomerLoanDownload?firmId=' + firmId),
      useCustomerCollectionDownload: firmId => axios.get('/channel/b/p/file/useCustomerCollectionDownload?firmId=' + firmId),
      repaymentCustomerDownload: firmId => axios.get('/channel/b/p/file/repaymentCustomerDownload?firmId=' + firmId),
      repaymentCustomerCoDownload: firmId => axios.get('/channel/b/p/file/repaymentCustomerCoDownload?firmId=' + firmId),
      repaymentCustomerPayDownload: firmId => axios.get('/channel/b/p/file/repaymentCustomerPayDownload?firmId=' + firmId),
      creditDownload: creditCode => axios.get('/channel/b/p/file/creditDownload?creditCode=' + creditCode),
      creditCreditDownload: creditCode => axios.get('/channel/b/p/file/creditCreditDownload?creditCode=' + creditCode),
      creditConDownload: creditCode => axios.get('/channel/b/p/file/creditConDownload?creditCode=' + creditCode),
      creditEncDownload: creditCode => axios.get('/channel/b/p/file/creditEncDownload?creditCode=' + creditCode),
      creditBasicDownload: creditCode => axios.get('/channel/b/p/file/creditBasicDownload?creditCode=' + creditCode),
      creditInvoiceDownload: creditCode => axios.get('/channel/b/p/file/creditInvoiceDownload?creditCode=' + creditCode),
      creditUseDownload: creditCode => axios.get('/channel/b/p/file/creditUseDownload?creditCode=' + creditCode),
      useCreditContractDownload: creditCode => axios.get('/channel/b/p/file/useCreditContractDownload?creditCode=' + creditCode),
      useCreditEnclosureDownload: creditCode => axios.get('/channel/b/p/file/useCreditEnclosureDownload?creditCode=' + creditCode),
      useCreditReDownload: creditCode => axios.get('/channel/b/p/file/useCreditReDownload?creditCode=' + creditCode),
      useCreditReInvoiceDownload: creditCode => axios.get('/channel/b/p/file/useCreditReInvoiceDownload?creditCode=' + creditCode),
      useCreditVoucherDownload: creditCode => axios.get('/channel/b/p/file/useCreditLoanDownload?creditCode=' + creditCode),
      useCreditCollectionDownload: creditCode => axios.get('/channel/b/p/file/useCreditCollectionDownload?creditCode=' + creditCode),
      repaymentCreditDownload: creditCode => axios.get('/channel/b/p/file/repaymentCreditDownload?creditCode=' + creditCode),
      repaymentCreditCoDownload: creditCode => axios.get('/channel/b/p/file/repaymentCreditCoDownload?creditCode=' + creditCode),
      repaymentCreditPayDownload: creditCode => axios.get('/channel/b/p/file/repaymentCreditPayDownload?creditCode=' + creditCode),
      useDownload: useCode => axios.get('/channel/b/p/file/useDownload?useCode=' + useCode),
      useUseDownload: useCode => axios.get('/channel/b/p/file/useUseDownload?useCode=' + useCode),
      useContractDownload: useCode => axios.get('/channel/b/p/file/useContractDownload?useCode=' + useCode),
      useEnclosureDownload: useCode => axios.get('/channel/b/p/file/useEnclosureDownload?useCode=' + useCode),
      useReDownload: useCode => axios.get('/channel/b/p/file/useReDownload?useCode=' + useCode),
      useReInvoiceDownload: useCode => axios.get('/channel/b/p/file/useReInvoiceDownload?useCode=' + useCode),
      useLoanDownload: useCode => axios.get('/channel/b/p/file/useLoanDownload?useCode=' + useCode),
      useCollectionDownload: useCode => axios.get('/channel/b/p/file/useCollectionDownload?useCode=' + useCode),
      repaymentDownload: useCode => axios.get('/channel/b/p/file/repaymentDownload?useCode=' + useCode),
      repaymentPayDownload: useCode => axios.get('/channel/b/p/file/repaymentPayDownload?useCode=' + useCode),
      repaymentCoDownload: useCode => axios.get('/channel/b/p/file/repaymentCoDownload?useCode=' + useCode),
    },
    cert: {
      listTenant: params => axios.get('/channel/b/p/sys/cert/list', { params: params }),
      execute: id => axios.get('/channel/b/p/sys/cert/execute?id=' + id),
      stop: id => axios.get('/channel/b/p/sys/cert/stop?id=' + id),
    },
    configure: {
      detailSystemGlobal: data => axios.post('/channel/sys/configure/detailSystemGlobal', data),
      detailLoginPage: data => axios.post('/channel/sys/configure/detailLoginPage', data),
      getConfigureDetail: data => axios.post('/channel/sys/configure/detail', data),
      addLoginPage: data => axios.post('/channel/b/p/configure/addLoginPage', data),
      addSystemGlobal: data => axios.post('/channel/b/p/configure/addSystemGlobal', data),
    }
  },
  workflow: {
    complete: (taskId, data) => axios.post('/workflow/b/p/sys/task/complete?taskId=' + taskId, data),
    addWorkFlow: data => axios.post('/channel/b/p/work/addWorkFlow', data),
    listPage: params => axios.get('/workflow/b/p/firm/flow/listPage', { params: params }),
    getUserTask: modelId => axios.get('/workflow/b/p/firm/flow/getUserTask?modelId=' + modelId),
    add: data => axios.post('/workflow/b/p/firm/flow/add', data),
    unboundList: params => axios.get('/channel/b/p/workflow/product/unboundList',{ params: params }),
    getProductTemplate: (productCode, flowKey) => axios.get(`/channel/b/p/workflow/task/contract/getProductTemplate?productCode=${productCode}&flowKey=${flowKey}`),
    getContractList: userTaskId => axios.get('/channel/b/p/workflow/task/contract/list?userTaskId=' + userTaskId),
    addContract: data => axios.post('/channel/b/p/workflow/task/contract/add', data),
    deleteContract: id => axios.delete('/channel/b/p/workflow/task/contract/delete?id=' + id),
    deleteById: modelId => axios.post('/workflow/b/p/firm/flow/deleteById?modelId=' + modelId),
    flowInit: modelId => axios.post('/workflow/b/p/firm/flow/init?modelId=' + modelId),
    save: data => axios.post('/workflow/b/p/firm/flow/save', data),
    details: modelId => axios.post('/workflow/b/p/firm/flow/details?modelId=' + modelId),
    getResourceImag: (modelId, resourceName) => axios.get(`/workflow/b/p/firm/flow/getResourceImage?modelId=${modelId}&resourceName=${resourceName}`),
    getRoleList: firmCode => axios.get('/workflow/b/p/firm/flow/getRoleList?firmCode=' + firmCode),
    getFlowImage: processInstanceId => axios.get('/workflow/b/p/firm/flow/getFlowImage?processInstanceId=' + processInstanceId),
    getFirmList: params => axios.get('/workflow/b/p/firm/flow/getFirmList', { params: params }),
    getDictList: params => axios.get('/workflow/b/p/sys/dict/list', { params: params }),
    getDictListExt: params => axios.get('/workflow/b/p/sys/dict/listExt', { params: params }),
    flowExpor: modelId => axios.post('/workflow/b/p/firm/flow/export?modelId=' + modelId),
    deployById: modelId => axios.post('/workflow/b/p/firm/flow/deployById?modelId=' + modelId),
    deployByTxt: data => axios.post('/workflow/b/p/firm/flow/deploy/by/txt', data)
  },
  operating: {
    listPage: params => axios.get('/channel/b/p/operating/listPage', { params: params })
  },
  dictInfo: {
    selectDictInfo: params  => axios.get('/channel/v1/mstDictInfo/selectDictInfoListByCode', { params: params })
  },
  // 字典类型操作S
  dict: {
    /**
     *  获得全部的字典通过dctTpCd 查询字典数据
     *   dom 样板 <el-select v-model="双向绑定数据" placeholder="请输入">
     <el-option v-for="item in option"
     :key="item.value"
     :label="item.label"
     :value="item.value"
     />
     </el-select>
     *  @param type (platform、client) + dctTpCd 必传 ***
     *  @return {lable: '', value: '', key: ''}
     *
     */
    getDictData (param) {
      const options = []
      if (param) {
        // const url = '/dict/getDict'
        const url = '/' + param.type + '/dict/getDict'
        // const urls = '/' + param.type + '/dict/getDictBatch'
        axios.get(url, { params: param }).then(res => {
          const data = res.data.data
          if (data && data.length > 0) {
            data.forEach(item => {
              options.push({ label: item.dctValNm, value: item.dctVal, key: item.dctKey })
            })
          }
        })
      }
      return options
    },
    /**
     *
     * @param type (platform、client) + dctTpCd 必传 *** + dctVal 必传
     * @return {[]}
     */
    getDictName (param) {
      let optionsName = '-'
      if (param) {
        axios.get('/' + param.type + '/dict/getDict', { params: param }).then(res => {
          const data = res.data.data
          if (data) {
            for (let i = 0; i < data.length; i++) {
              optionsName = data[i].dctValNm
            }
          }
        })
      }
      return optionsName
    },
    /**
     * 获取省市区 - 在一个select
     * dom <el-cascader
     v-model="双向绑定数据"
     :options="optionsJson"
     clearable
     filterable
     />
     */
    getAddress () {
      axios.get('/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData.replace(/code/g, 'value').replace(/name/g, 'label'))
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (typeof item.subList !== 'undefined' && item.subList !== null) {
            if (item.subList.length > 0) {
              item.children.map((itemN, indexN) => {
                itemN.children = itemN.subList
                delete itemN.subList
              })
            }
          }
        })
        return optionsJson
      })
    }
  }
}
export default api
