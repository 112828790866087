import Vue from 'vue'
import Vuex from 'vuex'
import { cookie } from '../assets/js/config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    // 账户名
    empAccount: '',
    empMobile: '',
    // 用户名
    empName: '',
    // 用户编码
    empCode: '',
    // 最后访问时间
    visitAt: '',
    // 企业信息
    companyInfo: {},
    // 浏览器标签图标
    globalLogo: '',
    // 系统名称
    globalWangName: '',
    // 是否显示修改密码弹框
    showUpdatePassWord: false,
    quantity: '',
    appKey: '',
    // 菜单
    sideMenu: [],
    // 选中菜单
    activeMenu: '',
    // 菜单拦伸缩状态
    spread: '',
    // 功能列表
    featureList: [],
    // 获取审核权限功能列表
    powerFeatureList: [],
    // 企业角色信息
    orgRoleList: [],
    // 工作流常规字典信息
    workflowRoutineDict: [],
    // 工作流扩展字典信息
    workflowExtendDict: {},
    // 工作流扩展名称信息
    workflowExtendName: []
  },
  mutations: {
    // 更新企业信息
    updateOrgRoleList (state, payload) {
      state.orgRoleList = payload
    },
    // 更新工作流常规字典信息
    updateWorkflowRoutineDict (state, payload) {
      state.workflowRoutineDict = payload
    },
    // 更新工作流扩展字典信息
    updateWorkflowExtendDict (state, payload) {
      state.workflowExtendDict = payload
    },
    // 更新工作流扩展名称信息
    updateWorkflowExtendName (state, payload) {
      state.workflowExtendName = payload
    },
    // 更新个性化配置信息
    updateConfigureInfo (state) {
      state.globalLogo = cookie.globalLogo()
      state.globalWangName = cookie.globalWangName()
    },
    // 移除个性化配置信息
    removeConfigureInfo (state) {
      state.globalLogo = ''
      state.globalWangName = ''
      cookie.removeCookie('globalLogo')
      cookie.removeCookie('globalWangName')
    },
    // 更新用户信息
    updateEmpInfo (state) {
      state.token = cookie.token()
      state.empAccount = cookie.empAccount()
      state.empMobile = cookie.empMobile()
      state.empName = cookie.empName()
      state.empCode = cookie.empCode()
      state.appKey = cookie.appKey()
      state.roleList = cookie.roleList()
      state.visitAt = cookie.visitAt()
    },
    // 移除用户信息
    removeEmpInfo (state) {
      state.token = ''
      state.empAccount = ''
      state.empMobile = ''
      state.empName = ''
      state.empCode = ''
      state.appKey = ''
      state.visitAt = ''
      cookie.removeCookie('token')
      cookie.removeCookie('empName')
      cookie.removeCookie('empMobile')
      cookie.removeCookie('empCode')
      cookie.removeCookie('empAccount')
      cookie.removeCookie('appKey')
      cookie.removeCookie('visitAt')
    },
    // 移除角色信息
    removeRuleInfo (state) {
      state.roleList = ''
      cookie.removeCookie('roleList')
    },
    // 更新菜单
    updateSideMenu (state, payload) {
      state.sideMenu = payload
    },
    // 更新菜单选中
    changeMenu (state, menuCode) {
      state.activeMenu = menuCode
    },
    // 更新侧栏伸缩状态
    isCollapse (state, spreadState) {
      state.spread = spreadState
    },
    updateQuantity (state, payload) {
      state.quantity = payload
    },
    // 更新当前运营企业信息
    updateSideCompany (state, payload) {
      state.companyInfo = payload
    },
    // 更新功能列表
    updateFeatureList (state, payload) {
      state.featureList = payload
    },
    // 获取审核权限功能列表
    updatePowerFeatureList (state, payload) {
      state.powerFeatureList = payload
    },
    // 更新修改密码弹框显示
    changeShowUpdatePassWord (state, payload) {
      state.showUpdatePassWord = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
