/**
 * system Created by Huyangjing on 2022/01/14.
 */
export default [
  {
    // 服务费明细管理
    path: '/serviceChargeManagement/record',
    name: 'serviceChargeRecordList',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      title: '服务费明细'
    },
    component: () => import('@/views/serviceChargeManagement/record/list')
  },
  {
    // 服务费明细详情
    path: '/serviceChargeManagement/record/detail',
    name: 'serviceChargeRecordDetail',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      parentName: 'serviceChargeRecordList',
      title: '详情'
    },
    component: () => import('@/views/serviceChargeManagement/record/detail')
  }
]
